<template>
    <div class="configure">
        <div class="search-box">
            <div class="search-one">
                <p class="left">
                    <span>员工</span>

                    <el-select
                        v-model="userName"
                        size="small"
                        style="width: 180px; margin-right: 16px"
                        clearable
                        placeholder="请选择"
                        @change="getTableData()"
                    >
                        <el-option
                            v-for="item in userNameData"
                            :key="item.id"
                            :label="item.nickName"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </p>
                <p>
                    <el-button
                        type="primary"
                        style="
                            margin-right: 16px;
                            border-color: #2370eb;
                            background: #2370eb;
                        "
                        size="small"
                        @click="getTableData()"
                        >查询</el-button
                    >
                    <el-button
                        @click="searchEmpty()"
                        type="primary"
                        style="
                            margin-right: 16px;
                            background: none;
                            border-color: #d9d9d9;
                            color: #333333;
                        "
                        icon="el-icon-refresh-left"
                        size="small"
                        >重置</el-button
                    >
                </p>
            </div>
        </div>
        <div class="table-title">
            <p>关联配置列表</p>
            <span> </span>
        </div>
        <el-table
            class="table-box"
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column prop="" width="10"> </el-table-column>
            <el-table-column label="联系人" prop="shareAdminName" width="60">
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="角色"
                :formatter="roleType"
                show-overflow-tooltip
                width="100"
            >
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="共享人" width="60" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{
                        scope.row.receiveAdminName
                            ? scope.row.receiveAdminName
                            : '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="到期时间"
                prop="status"
                show-overflow-tooltip
                width="160"
            >
                <template slot-scope="scope">
                    <span>{{
                        scope.row.expireTime ? scope.row.expireTime : '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="操作"
                fixed="right"
                width="120"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <el-button
                        type="text"
                        style="color: #2370eb"
                        @click="onEdit(scope.row)"
                        >配置</el-button
                    >
                </template>
            </el-table-column>
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
        <div class="page-box">
            <el-pagination
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
        <el-dialog
            :visible.sync="drawer"
            width="560px"
            :before-close="handleClose"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    配置
                </div>
            </template>
            <Config @close="handleClose" ref="config" />
        </el-dialog>
    </div>
</template>
<script>
import { configList } from '@/api/configure/configure';
import { roleList } from '@/api/customer/customer';
import Config from './config.vue';
export default {
    components: {
        Config,
    },
    data() {
        return {
            userName: '',
            userNameData: [],
            tableData: [],
            pagesize: 20,
            total: 0,
            currentPage: 1,
            drawer: false,
        };
    },
    mounted() {
        this.getFollowList();
    },
    methods: {
        getFollowList() {
            let data = {
                param: {
                    //   departmentId: sessionStorage.getItem("departmentId"),
                    roleTypeList: [2, 3],
                },
            };
            if (sessionStorage.getItem('userType') != 15) {
                data.param.departmentId =
                    sessionStorage.getItem('departmentId');
            }
            roleList(data).then((res) => {
                this.userNameData = res.data;
                this.getTableData();
            });
        },
        getTableData() {
            let data = {
                param: {
                    roleTypeList: [2, 3],
                },
                pageNum: this.currentPage,
                pageSize: this.pagesize,
            };
            if (sessionStorage.getItem('userType') != 15) {
                data.param.departmentId =
                    sessionStorage.getItem('departmentId');
            }
            if (this.userName) {
                data.param.receiveAdminId = this.userName;
            }
            configList(data).then((res) => {
                // console.log(res);
                this.total = res.data.total;
                this.tableData = res.data.list;
            });
        },

        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getTableData();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.getTableData();
        },
        roleType(row) {
            if (row.roleType == 2) {
                return 'SDR';
            } else if (row.roleType == 3) {
                return 'MDR';
            } else if (row.roleType == 4) {
                return 'AE';
            }
        },
        // 配置
        onEdit(row) {
            // console.log(row);
            this.drawer = true;
            setTimeout(() => {
                this.$refs.config.getData(row);
            });
        },
        handleClose() {
            this.drawer = false;
            this.getTableData();
        },
        searchEmpty() {},
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.configure {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.search-box {
    width: 100%;
    background: #fff;
    border-radius: 4px;
    margin-bottom: 16px;
    span {
        font-size: 12px;
        font-weight: 500;
        color: #333333;
        margin: 0 16px;
    }
    .search-one {
        padding: 10px 0 12px 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .left {
            button {
                background: #2370eb;
                border-radius: 2px;

                margin-right: 16px;
            }
        }
    }
}
.table-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 16px;
    .add {
        border-color: #d9d9d9;
        color: #333333;
    }
    p {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
    }

    button {
        font-size: 12px;
        padding: 0 7px 0 12px;
        height: 24px;
        background: #ffffff;
        border-radius: 2px;
        border: 1px solid #2370eb;
        cursor: pointer;
        color: #2370eb;
        margin-left: 16px;
    }

    button:hover {
        background: #2370eb;
        color: #ffffff;
        border-color: #2370eb;
    }
}
.page-box {
    background: #fff;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
