<template>
    <div class="config">
        <div class="config_box">
            <div class="title_one">
                <p>
                    <span>员工姓名</span>

                    <span>
                        {{ data.shareAdminName }}
                    </span>
                </p>
                <p>
                    <span>员工角色</span>
                    <span>
                        {{
                            data.roleType == 2
                                ? 'SDR'
                                : data.roleType == 3
                                ? 'MDR'
                                : data.roleType == 4
                                ? 'AE'
                                : '- -'
                        }}
                    </span>
                </p>
            </div>
            <div class="title_two">
                <span>到期时间</span>
                <el-date-picker
                    v-model="expireTime"
                    size="small"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    style="width:240px;margin-left:10px;margin-right:20px"
                    type="date"
                    placeholder="选择日期"
                >
                </el-date-picker>
                <el-radio-group v-model="expireData" size="small">
                    <el-radio-button
                        label="30天"
                        @click.native.prevent="clickTime('30天')"
                    ></el-radio-button>

                    <el-radio-button
                        label="90天"
                        @click.native.prevent="clickTime('90天')"
                    ></el-radio-button>
                </el-radio-group>
            </div>
            <p style="color:#333333;padding-bottom:12px">
                请选择线索接收人（勾选后线索信息将共享给接收人）
            </p>
            <div class="table">
                <el-table
                    class="table-box"
                    ref="multipleTable"
                    :data="tableData"
                    height="400px"
                    :header-cell-style="{
                        background: '#FAFAFA',
                        color: '#333333',
                        'font-size': '14px',
                        'font-weight': '500',
                    }"
                >
                    <el-table-column label="选择" width="55">
                        <template slot-scope="scope">
                            <el-radio
                                v-model="data.receiveAdminId"
                                :label="scope.row.id"
                                ><i></i
                            ></el-radio>
                        </template>
                    </el-table-column>
                    <el-table-column label="姓名" prop="nickName">
                    </el-table-column>
                    <el-table-column
                        label="角色"
                        :formatter="roleType"
                        show-overflow-tooltip
                    >
                    </el-table-column>

                    <div slot="empty" class="empty">
                        <img src="@/assets/zanwushuju.png" />
                        <span style="display:block">暂无数据～</span>
                    </div>
                </el-table>
            </div>
        </div>
        <div class="but">
            <el-button
                round
                type="primary"
                size="mini"
                style="background:none;border-color:#D5D6DF;color:#666666;margin-left: auto;"
                @click="onClose"
                >取消</el-button
            >
            <el-button
                round
                type="primary"
                style="background:#2370EB;border-color:#2370EB;color:#fff;margin-right:30px"
                size="mini"
                @click="onSubmit"
                >确定</el-button
            >
        </div>
    </div>
</template>
<script>
import { roleList } from '@/api/customer/customer';
import { configAdd } from '@/api/configure/configure';
export default {
    data() {
        return {
            data: {},
            expireTime: '',
            expireData: '',
            tableData: [],
        };
    },
    methods: {
        getData(data) {
            this.data = data;
            this.getTableData();
        },
        getTableData() {
            let data = {
                param: {
                    departmentId: sessionStorage.getItem('departmentId'),
                    roleTypeList: [4,8,9,10],
                },
            };
            roleList(data).then((res) => {
                this.tableData = res.data;
            });
        },

        handleSelectionChange() {},
        // 到期时间点击
        clickTime(e) {
            // console.log(e);
            e === this.expireData
                ? (this.expireData = '')
                : (this.expireData = e);
            let date = new Date();
            if (e == this.expireData && e == '30天') {
                this.expireTime =
                    this.$searchTime.getNextDate(date, -30) + ' 00:00:00';
            } else if (e == this.expireData && e == '90天') {
                this.expireTime =
                    this.$searchTime.getNextDate(date, -90) + ' 00:00:00';
            } else {
                this.expireTime = '';
            }
        },
        roleType(row) {
            if (row.roleType == 4) {
                return 'AE';
            } else {
                return 'AM';
            }
        },
        onClose() {
            this.expireTime = '';
            this.expireData = '';
            this.$emit('close');
        },
        onSubmit() {
            // console.log(this.data.receiveAdminId);
            if (!this.expireTime) {
                return this.$message.error('请选择到期时间');
            }
            if (!this.data.receiveAdminId) {
                return this.$message.error('请选择关联成员');
            }
            let data = {
                param: {
                    expireTime: this.expireTime,
                    receiveAdminId: this.data.receiveAdminId,
                    shareAdminId: this.data.shareAdminId,
                },
            };
            configAdd(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.onClose();
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.config {
    height: 600px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    .config_box {
        flex: 1;
        padding: 24px 25px 30px 35px;
        display: flex;
        flex-direction: column;

        .title_one {
            display: flex;
            p:nth-child(2) {
                margin-left: 160px;
            }
            p {
                span:nth-child(2) {
                    color: #333;
                }
            }
        }
        .title_two {
            padding: 12px 0;
        }
        .table {
            border: 1px solid #e5e5e5;
            border-radius: 3px;
        }
    }
}
.but {
    width: 100%;
    height: 48px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    background: #fff;
    justify-content: space-between;
    button {
        width: 72px;
    }
}
</style>
